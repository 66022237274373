import React from 'react'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { offices } from '/static/data/aboutUs'
import Icon from 'components/icon/index'
import Layout from 'layouts/index'
import Seo from 'components/seo'
import Twitter from '../components/icon/twiiter.jsx'
import { Tooltip } from '@mui/material'
const AboutPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('aboutUs.meta.title'),
            metaDescription: t('aboutUs.meta.description'),
            metaUrl: 'https://www.mlytics.com/about/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className="container flex justify-center md:flex-row pt-[5rem] pb-[6em]">
          <div className="flex-1 text-center px-[15%]">
            <p className="pb-5 text-lg text-blue-200">{t('aboutUs.section1.subTitle')}</p>
            <h1 className="leading-snug text-primary-500 text-4xl lg:text-[3em] w-full">
              {t('aboutUs.section1.title')}
            </h1>
          </div>
        </section>
        <section className="bg-primary-500  ">
          <div className="container flex flex-col-reverse md:flex-row gap-5 md:gap-10 py-20">
            <div className="flex-1 flex flex-col items-center  md:block ">
              <h2 className="text-4xl text-white mb-5">{t('aboutUs.section2.title')}</h2>
              <p className="pb-7 pt-2 leading-loose text-center md:text-start text-base text-white mb-5">
                {t('aboutUs.section2.subTitle')}
              </p>
              <img
                src={'https://www.mlytics.com/wp-content/uploads/2022/07/logo_M_white.svg'}
                alt={t('aboutUs.section2.title')}
                className="w-[200px]"
              />
            </div>
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2020/01/4.jpg"
                alt={t('aboutUs.section2.title')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
          </div>
        </section>
        <section className="container flex flex-col-reverse md:flex-row-reverse gap-0 md:gap-5 py-20">
          <div className="flex-1">
            <h2 className="mt-12 text-4xl text-primary-500 mb-5">{t('aboutUs.section3.title')}</h2>
            <p className="pb-7 pt-2 text-base text-gray-600 mb-5 leading-loose">{t('aboutUs.section3.content')}</p>
          </div>
          <div className="flex flex-1 items-center overflow-hidden">
            <img
              src="https://www.mlytics.com/wp-content/uploads/2021/09/ISO-cert.png"
              alt={t('aboutUs.section2.title')}
              style={{ height: 'auto', maxWidth: '550px' }}
              className="w-full"
            />
          </div>
        </section>

        <section className="bg-gray-50 pt-20 pb-20 ">
          <div className="container">
            <h2 className="mt-12 text-4xl text-primary-500 mb-5 text-center w-full">{t('aboutUs.section4.title')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-6 gap-5 mt-20 ">
              {offices.map(({ title, image, address }, index) => (
                <div
                  key={`${index}_cardAddress`}
                  className="flex flex-col md:block items-center rounded-[20px] shadow-md bg-white p-8  transition-transform duration-300 ease-in-out hover:-translate-y-2"
                >
                  <div className="relative">
                    <img src={image} alt={t(title)} className="md:absolute w-24 h-24 bottom-[-10px]" />
                  </div>

                  <h4 className="mt-8 mb-3 text-2xl md:text-3xl text-primary-500">{t(title)}</h4>
                  <p className="text-gray-600 leading-relaxed mb-6 text-sm md:text-base text-center md:text-start">
                    {t(address)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <ContactUs />
        {/* <GotoDemoHero /> */}
      </React.Fragment>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
const Facebook = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#fff"
      d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5Z"
    />
  </svg>
)

const Linkedin = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#fff"
      d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002ZM7 8.48H3V21h4V8.48Zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68Z"
    />
  </svg>
)

const WeChat = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <path
      fill="#fff"
      d="M21.502 19.525c1.524-1.105 2.498-2.738 2.498-4.554 0-3.326-3.237-6.023-7.229-6.023s-7.229 2.697-7.229 6.023c0 3.327 3.237 6.024 7.229 6.024.825 0 1.621-.117 2.36-.33l.212-.032c.139 0 .265.043.384.111l1.583.914.139.045c.133 0 .241-.108.241-.241l-.039-.176-.326-1.215-.025-.154c0-.162.08-.305.202-.392zm-12.827-17.228c-4.791 0-8.675 3.236-8.675 7.229 0 2.178 1.168 4.139 2.997 5.464.147.104.243.276.243.471l-.03.184-.391 1.458-.047.211c0 .16.13.29.289.29l.168-.054 1.899-1.097c.142-.082.293-.133.46-.133l.255.038c.886.255 1.842.397 2.832.397l.476-.012c-.188-.564-.291-1.158-.291-1.771 0-3.641 3.542-6.593 7.911-6.593l.471.012c-.653-3.453-4.24-6.094-8.567-6.094zm5.686 11.711c-.532 0-.963-.432-.963-.964 0-.533.431-.964.963-.964.533 0 .964.431.964.964 0 .532-.431.964-.964.964zm4.82 0c-.533 0-.964-.432-.964-.964 0-.533.431-.964.964-.964.532 0 .963.431.963.964 0 .532-.431.964-.963.964zm-13.398-5.639c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156zm5.783 0c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156z"
    />
  </svg>
)

export function ContactUs() {
  const { t } = useTranslation()
  const { language } = useI18next()
  const { screenM } = useBreakpoint()

  return (
    <section className=" py-[10rem] ">
      <div className="container">
        <h2 className=" text-4xl text-primary-500 mb-[50px] text-center w-full">{t('aboutUs.section5.title')}</h2>
        <div className="flex justify-center">
          <a
            title={t('aboutUs.section5.icon1')}
            href="https://www.linkedin.com/company/mlytics/"
            target="_blank"
            rel="noreferrer"
            className="rounded-full cursor-pointer mx-1 bg-secondary-600 w-12 h-12 flex justify-center items-center"
          >
            <Linkedin />
          </a>
          <a
            title={t('aboutUs.section5.icon2')}
            href="https://twitter.com/mlyticsHQ"
            target="_blank"
            rel="noreferrer"
            className="rounded-full cursor-pointer mx-1 bg-secondary-600 w-12 h-12 flex justify-center items-center"
          >
            <Twitter className="w-[24px]" />
          </a>
          <a
            title={t('aboutUs.section5.icon3')}
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/mlytics/"
            className="rounded-full cursor-pointer mx-1 bg-secondary-600 w-12 h-12 flex justify-center items-center"
          >
            <Facebook />
          </a>
          <a
            title={t('aboutUs.section5.icon4')}
            target="_blank"
            rel="noreferrer"
            href="mailto:sales@mlytics.com"
            className="rounded-full cursor-pointer mx-1 bg-secondary-600 w-12 h-12 flex justify-center items-center"
          >
            <Icon name="mail" className="text-white" />
          </a>
          {language === 'zh' &&
            (!screenM ? (
              <a
                title={t('aboutUs.section5.icon5')}
                target="_blank"
                rel="noreferrer"
                href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzk0MzcwNzk2Mw=="
                className="rounded-full cursor-pointer mx-1 bg-secondary-600 w-12 h-12 flex justify-center items-center"
              >
                <WeChat />
              </a>
            ) : (
              <div>
                <React.Fragment>
                  <Tooltip
                    title={
                      <div className="flex flex-col items-center w-[180px]">
                        <p className="text-base">微信扫码关注公众号</p>
                        <p className="text-base">联系我们线上客服</p>
                        <StaticImage
                          src="https://www.mlytics.com/wp-content/uploads/2024/07/wechat_qrcode_mlytics.jpg"
                          alt="mlytics wechat qrcode"
                          objectFit="contain"
                          style={{ height: '180px', width: '180px', marginBottom: '4px' }}
                        />
                      </div>
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#3A3A3A'
                        }
                      }
                    }}
                  >
                    <div className="rounded-full cursor-pointer mx-1 bg-secondary-600 w-12 h-12 flex justify-center items-center">
                      <WeChat />
                    </div>
                  </Tooltip>
                </React.Fragment>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}
