import * as React from 'react'
const Twitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: 'new 0 0 400 400'
    }}
    viewBox="0 0 400 400"
    {...props}
  >
    <style>{'.st0{fill:#fff}'}</style>
    <path
      d="M360.8 352.4 235.3 170l-14.2-20.6-89.8-130.6L123.8 8H13.5l26.9 39.1 119.4 173.5 14.2 20.6 96 139.4 7.4 10.8h110.4l-27-39zm-70.3 14.1-99.7-144.8-14.2-20.6L61 33h49.8l93.5 135.9 14.2 20.6 121.8 177h-49.8z"
      className="st0"
    />
    <path
      d="m176.6 201.1 14.2 20.6-16.8 19.6L44.9 391.5H13l146.8-170.8zM374.5 8 235.3 170l-16.8 19.5-14.2-20.6 16.8-19.6 94.3-109.7L342.6 8z"
      className="st0"
    />
  </svg>
)
export default Twitter
