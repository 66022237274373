import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import Layout from 'layouts/index'
import Seo from 'components/seo'
import { ContactUs } from './about'

const AboutPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('pressResources.meta.title'),
            // metaDescription: t('aboutUs.meta.description'),
            metaUrl: 'https://www.mlytics.com/press-resources/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className="container flex justify-center md:flex-row pt-12 md:pt-[5rem] pb-20 md:pb-[1em]">
          <div className="flex-1 text-center md:px-[15%]">
            <p className="pb-5 text-lg text-blue-200">{t('pressResources.subTitle')}</p>
            <h2 className="leading-normal	 text-primary-500 text-[2.5em] lg:text-[3.2em] w-full">
              {t('pressResources.title')}
            </h2>
          </div>
        </section>
        <section className="container  md:flex-row pt-[5rem] pb-[6em]">
          <div className="mb-8">
            <h3 className="leading-snug text-center md:text-start text-primary-500 text-[2em] lg:text-4xl w-full mb-5">
              {t('pressResources.section1.title')}
            </h3>
            <p className=" pt-2 leading-loose text-center md:text-start text-base text-gray-600 pb-[1.8em]">
              {t('pressResources.section1.paragraph1')}
            </p>
          </div>
          <div className="mb-8">
            <h3 className="leading-snug text-center md:text-start text-primary-500 text-[2em] lg:text-4xl w-full mb-5">
              {t('pressResources.section2.title')}
            </h3>
            <p className="pt-2 leading-loose text-center md:text-start text-base text-gray-600 pb-[1.8em]">
              {t('pressResources.section2.paragraph1')}
            </p>
            <p className=" pt-2 leading-loose text-center md:text-start text-base text-gray-600 pb-[1.8em]">
              {t('pressResources.section2.paragraph2')}
            </p>
          </div>
        </section>
        <section className="bg-gray-50  ">
          <div className="container flex flex-col-reverse md:flex-row gap-5 md:gap-10 py-20">
            <div className="flex-1 flex flex-col items-center  md:block ">
              <h2 className="text-4xl text-primary-500  mb-5">{t('pressResources.section3.title')}</h2>
              <p className="pb-5 pt-2 leading-loose text-center md:text-start text-base text-gray-600  mb-5">
                <Trans i18nKey={'pressResources.section3.content'}>
                  <a
                    href="mailto:marketing@mlytics.com"
                    className="text-secondary-600 transition-all duration-200 ease-in-out hover:text-secondary-700"
                  ></a>
                </Trans>
              </p>
              <a
                href="https://drive.google.com/file/d/1UTjm2B4IofFJR9vr_B6lhfk2MfoznF27/view"
                className=" btn btn-orange "
                style={{ padding: '16px 20px' }}
              >
                {t('pressResources.section3.download')}
              </a>
            </div>
            <div className="flex flex-col flex-1 items-center overflow-hidden md:mb-0 mb-4">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2020/07/a-2.jpg"
                alt={t('pressResources.section3.title')}
                className="w-full"
                style={{ height: 'auto', maxWidth: '550px' }}
              />
              <img
                src="https://www.mlytics.com/wp-content/uploads/2020/07/b-2.png"
                alt={t('pressResources.section3.title')}
                className="w-full"
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
          </div>
        </section>

        <ContactUs />
        {/* <GotoDemoHero /> */}
      </React.Fragment>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
