export const offices = [
  {
    title: 'aboutUs.section4.card1.title',
    address: 'aboutUs.section4.card1.address',
    image: 'https://www.mlytics.com/wp-content/uploads/2021/09/Artboard-Copy_1.png'
  },
  {
    title: 'aboutUs.section4.card2.title',
    address: 'aboutUs.section4.card2.address',
    image: 'https://www.mlytics.com/wp-content/uploads/2020/01/Artboard-Copy-2.png'
  },
  {
    title: 'aboutUs.section4.card3.title',
    address: 'aboutUs.section4.card3.address',
    image: 'https://www.mlytics.com/wp-content/uploads/2020/01/Artboard__1.png'
  }
]
